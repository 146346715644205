/* Generated by Font Squirrel (http://www.fontsquirrel.com) on July 6, 2015 */



@font-face {
    font-family: 'DB Sans Regular';
    src: url('dbsan03-webfont.eot');
    src: url('dbsan03-webfont.eot?#iefix') format('embedded-opentype'),
         url('dbsan03-webfont.woff2') format('woff2'),
         url('dbsan03-webfont.woff') format('woff'),
         url('dbsan03-webfont.ttf') format('truetype'),
         url('dbsan03-webfont.svg#db_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'DB Sans Bold';
    src: url('dbsan06-webfont.eot');
    src: url('dbsan06-webfont.eot?#iefix') format('embedded-opentype'),
         url('dbsan06-webfont.woff2') format('woff2'),
         url('dbsan06-webfont.woff') format('woff'),
         url('dbsan06-webfont.ttf') format('truetype'),
         url('dbsan06-webfont.svg#db_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'DB Sans Black Regular';
    src: url('dbsan07-webfont.eot');
    src: url('dbsan07-webfont.eot?#iefix') format('embedded-opentype'),
         url('dbsan07-webfont.woff2') format('woff2'),
         url('dbsan07-webfont.woff') format('woff'),
         url('dbsan07-webfont.ttf') format('truetype'),
         url('dbsan07-webfont.svg#db_sans_blackregular') format('svg');
    font-weight: normal;
    font-style: normal;

}
