/* Generated by Font Squirrel (http://www.fontsquirrel.com) on July 6, 2015 */



@font-face {
    font-family: 'DB Head Light Regular';
    src: url('dbhea02-webfont.eot');
    src: url('dbhea02-webfont.eot?#iefix') format('embedded-opentype'),
         url('dbhea02-webfont.woff2') format('woff2'),
         url('dbhea02-webfont.woff') format('woff'),
         url('dbhea02-webfont.ttf') format('truetype'),
         url('dbhea02-webfont.svg#db_head_lightregular') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'DB Head Regular';
    src: url('dbhea03-webfont.eot');
    src: url('dbhea03-webfont.eot?#iefix') format('embedded-opentype'),
         url('dbhea03-webfont.woff2') format('woff2'),
         url('dbhea03-webfont.woff') format('woff'),
         url('dbhea03-webfont.ttf') format('truetype'),
         url('dbhea03-webfont.svg#db_headregular') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'DB Head Black';
    src: url('dbhea07-webfont.eot');
    src: url('dbhea07-webfont.eot?#iefix') format('embedded-opentype'),
         url('dbhea07-webfont.woff2') format('woff2'),
         url('dbhea07-webfont.woff') format('woff'),
         url('dbhea07-webfont.ttf') format('truetype'),
         url('dbhea07-webfont.svg#db_headblack') format('svg');
    font-weight: normal;
    font-style: normal;

}
