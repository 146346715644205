/* You can add global styles to this file, and also import other style files */

// import DBBP fonts
@import 'assets/fonts/db-head/stylesheet.css';
@import 'assets/fonts/db-sans/stylesheet.css';

// color variables
$dbbp-blue: #004171;

// set navbar height
$navbar-height: 75px;

// change native theme colors
$theme-colors: (
  'primary': $dbbp-blue
);

// stylelint-disable value-keyword-case
$font-family-sans-serif: "DB Sans Regular", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$headings-font-family:  "DB Head Regular", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
// Override Bootstrap SCSS variables
// $link-color: #ffffff; // Custom link color
// $link-decoration: none; // Custom link text decoration
// $link-hover-color: #d3d3d3; // Custom hover color for links
// $link-hover-decoration: none; // Custom hover text decoration for links

// import bootstrap 5 scss
@import 'bootstrap/scss/bootstrap';

// boostrap 5
@import 'ngx-toastr/toastr-bs5-alert';

// global styles
html, body {
    height: calc(100% - #{$navbar-height});
}
.bg-dbbp {
    background-color: $dbbp-blue;
} 
.toast-container {
    top: 10px;

    &.toast-top-center {
        .ngx-toastr {
            min-width: 50%;
        }
    }

    &.toast-bottom-full-width,
    &.toast-top-full-width {
        .ngx-toastr {
            width: calc(100% - 30px);
        }
    }
}
